<template>
    <div ref="logistic_main" class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">Update Status</h1>
        <div class="d-flex flex-wrap gap-2 justify-content-between" v-if="$store.getters['get_perm']('showLogistic')">
            <span>Tatsing Group > Update Status</span>
            <form @submit.prevent="getLogistic(this.srn_to_search)">
                <div class="d-flex flex-row w-100 gap-2">
                    <div class="d-flex">
                        <label for="search" class="border border-end-0 rounded-start py-2 px-3">
                            <img src="../../../assets/imgs/magnifying-glass.png" alt="magnifying-glass">
                        </label>
                        <input v-model.lazy="srn_to_search" type="text" placeholder="Enter SRN" class="w-100 border border-start-0 rounded-end py-2 input-outline" required>
                    </div>
                    <button type="submit" class="bg-theme text-white py-2 rounded border-0 px-5" style="min-width: 150px;" :disabled="isLoading">Search</button>
                </div>
            </form>
        </div>
        <div class="d-flex flex-column justify-content-center gap-4">
            <div class="w-100" style="border-top: 3px dashed rgba(0,0,0,.125);"></div>
            <button type="button" class="rounded bg-theme text-light"
            @click="goBack" style="width: 150px;" v-show="isUpdating || hasError"
            v-if="$store.getters['get_perm']('storeLogistic')">Return to Create SRN</button>

            <div class="text-center p-0 fw-bold" style="font-size: large;"
                v-show="message"
                :class="{'text-success': message.includes('ucces'), 'text-danger': !message.includes('ucces')}">
                {{ message }}
            </div>
            
            <div ref="form" v-if="$store.getters['get_perm']('storeLogistic') || $store.getters['get_perm']('updateLogistic')">
                <PreloaderVue v-show="isLoading" />
                <form id="srn-forms" @submit.prevent="createLogistic">
                    <div class="d-flex flex-column justify-content-center gap-4 position-relative">
                        <div class="d-flex gap-2 ms-auto">
                            <div class="tooltippers"><i class="bi bi-info-circle"></i>
                                <span class="tooltiptext">This will remind you every time, everyday until the specific "TF" is not finished.</span>
                            </div>
                            <label>Remind me everyday at:</label>
                            <select v-model="logistic_data.remind_at">
                                <option value="5">5 Mins</option>
                                <option value="10">10 Mins</option>
                                <option value="15">15 Mins</option>
                                <option value="30">30 Mins</option>
                                <option value="60">1 Hour</option>
                                <option value="90">1 Hour and 30 Mins</option>
                                <option value="120">2 Hours</option>
                            </select>
                        </div>
                        <div class="card shadow-sm d-flex flex-column justify-content-center" :class="{'border-danger': other_errors.length}">
                            <div class="card py-3 px-2 px-md-5">
                                    <div class="text-danger align-middle">
                                        <ul>
                                            <li v-for="error in other_errors" :key="error">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                                <div class="w-75 gap-3 d-flex align-items-center justify-content-center mx-auto">
                                    <label for="srn" class="fw-bold" style="font-size: large;">{{ srn_label }}</label>
                                    <input v-model.lazy="logistic_data.srn" id="srn" type="text" placeholder="TF620000" class="border rounded py-2 px-2 w-75 text-theme fw-bold" autocomplete="nope" required>
                                </div>
                                <div class="d-flex flex-column gap-3 mx-auto w-100 border-top mt-2">
                                    <label for="items" class="fw-bold" style="font-size: large;">Item Description</label>
                                    <textarea id="items" v-model="logistic_data.items" placeholder="Description"
                                    cols="10" rows="10" class="w-100 p-2 rounded shadow-sm" style=" height: 125px;"></textarea>
                                </div>
                            </div>
                            <div class="card py-3 px-4">
                                <div class="ms-auto">
                                    <button type="submit" class="bg-theme text-white py-2 rounded border-0" style="width: 100px;" :disabled="isLoading">{{ srn_button }}</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex">
                                <div class="position-relative mt-4">
                                    <div name="circle" class="circle"></div>
                                    <div class="line"></div>
                                </div>
                                <div name="edit-tab" class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': doc_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">Documents Received</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.doc_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.doc_date) }} <span v-show="logistic_data.doc_date && logistic_data.doc_time">|</span> {{ TimeFormat(logistic_data.doc_time) }}</span>
                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1">
                                                <img src="#" name="edit-button-icon" @click="openTab(0)" alt="edit" width="25">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(0)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">Documents Received</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in doc_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.doc_desc" id="description" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.doc_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.doc_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(0)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': etd_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">ETD of the Vessel</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.etd_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.etd_date) }} <span v-show="logistic_data.etd_date && logistic_data.etd_time">|</span> {{ TimeFormat(logistic_data.etd_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.doc_desc || !logistic_data.doc_date || !logistic_data.doc_time" @click="openTab(1)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25" :class="{'opacity-50': !logistic_data.doc_desc || !logistic_data.doc_date || !logistic_data.doc_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(1)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">ETD of the Vessel</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in etd_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.etd_desc" id="etd" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.etd_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.etd_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(1)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': eta_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">ETA of the Vessel</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.eta_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.eta_date) }} <span v-show="logistic_data.eta_date && logistic_data.eta_time">|</span> {{ TimeFormat(logistic_data.eta_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.etd_desc || !logistic_data.etd_date || !logistic_data.etd_time" @click="openTab(2)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25" :class="{'opacity-50': !logistic_data.etd_desc || !logistic_data.etd_date || !logistic_data.etd_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(2)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">ETA of the Vessel</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in eta_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.eta_desc" id="eta" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.eta_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.eta_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(2)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': etb_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">ETB of the Vessel</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4"> {{ logistic_data.etb_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.etb_date) }} <span v-show="logistic_data.etb_date && logistic_data.etb_time">|</span> {{ TimeFormat(logistic_data.etb_time) }}</span>
                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.eta_desc || !logistic_data.eta_date || !logistic_data.eta_time" @click="openTab(3)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.eta_desc || !logistic_data.eta_date || !logistic_data.eta_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(3)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">ETB of the Vessel</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in etb_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.etb_desc" id="etb" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.etb_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.etb_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(3)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': endorsement_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Shipping Line Endorsement</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.endorsement_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.endorsement_date) }} <span v-show="logistic_data.endorsement_date && logistic_data.endorsement_time">|</span> {{ TimeFormat(logistic_data.endorsement_time) }}</span>
                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.etb_desc || !logistic_data.etb_date || !logistic_data.etb_time" @click="openTab(4)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.etb_desc || !logistic_data.etb_date || !logistic_data.etb_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(4)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Shipping Line Endorsement</h2>
                                        <div class="text-danger align-middle">
                                            <ul>
                                                <li v-for="error in endorsement_errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.endorsement_desc" id="endorsment" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.endorsement_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.endorsement_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(4)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': customs_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Customs Processing</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.customs_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.customs_date) }} <span v-show="logistic_data.customs_date && logistic_data.customs_time">|</span> {{ TimeFormat(logistic_data.customs_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.endorsement_desc || !logistic_data.endorsement_date || !logistic_data.endorsement_time" @click="openTab(5)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.endorsement_desc || !logistic_data.endorsement_date || !logistic_data.endorsement_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(5)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Customs Processing</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in customs_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.customs_desc" id="customs" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.customs_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.customs_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(5)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': assessment_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Final Assessment</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.assessment_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.assessment_date) }} <span v-show="logistic_data.assessment_date && logistic_data.assessment_time">|</span> {{ TimeFormat(logistic_data.assessment_time) }}</span>


                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.customs_desc || !logistic_data.customs_date || !logistic_data.customs_time" @click="openTab(6)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.customs_desc || !logistic_data.customs_date || !logistic_data.customs_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(6)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Final Assessment</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in assessment_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                
                                                <textarea v-model="logistic_data.assessment_desc" id="assessment" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.assessment_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.assessment_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(6)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': paidDuties_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">Paid Duties</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.paidDuties_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.paidDuties_date) }} <span v-show="logistic_data.paidDuties_date && logistic_data.paidDuties_time">|</span> {{ TimeFormat(logistic_data.paidDuties_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.assessment_desc || !logistic_data.assessment_date || !logistic_data.assessment_time" @click="openTab(7)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                :class="{'opacity-50':!logistic_data.assessment_desc || !logistic_data.assessment_date || !logistic_data.assessment_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(7)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">Paid Duties</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in paidDuties_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                
                                                <textarea v-model="logistic_data.paidDuties_desc" id="paidDuties" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.paidDuties_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.paidDuties_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(7)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': arrastre_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Arrastre/Wharfage Payment</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.arrastre_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.arrastre_date) }} <span v-show="logistic_data.arrastre_date && logistic_data.arrastre_time">|</span> {{ TimeFormat(logistic_data.arrastre_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.paidDuties_desc || !logistic_data.paidDuties_date || !logistic_data.paidDuties_time" @click="openTab(8)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.paidDuties_desc || !logistic_data.paidDuties_date || !logistic_data.paidDuties_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(8)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Arrastre/Wharfage Payment</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in arrastre_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                
                                                <textarea v-model="logistic_data.arrastre_desc" id="arrastre" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.arrastre_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.arrastre_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(8)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': tabs_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">TABS Booking</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.tabs_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.tabs_date) }} <span v-show="logistic_data.tabs_date && logistic_data.tabs_time">|</span> {{ TimeFormat(logistic_data.tabs_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.arrastre_desc || !logistic_data.arrastre_date || !logistic_data.arrastre_time" @click="openTab(9)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25" :class="{'opacity-50':!logistic_data.arrastre_desc || !logistic_data.arrastre_date || !logistic_data.arrastre_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(9)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>

                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">TABS Booking</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in tabs_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.tabs_desc" id="tabs" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.tabs_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.tabs_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(9)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div class="line"></div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': gatepass_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Gatepass</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.gatepass_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">{{ DateFormat(logistic_data.gatepass_date) }} <span v-show="logistic_data.gatepass_date && logistic_data.gatepass_time">|</span> {{ TimeFormat(logistic_data.gatepass_time) }}</span>

                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.tabs_desc || !logistic_data.tabs_date || !logistic_data.tabs_time" @click="openTab(10)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25" :class="{'opacity-50':!logistic_data.tabs_desc || !logistic_data.tabs_date || !logistic_data.tabs_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(10)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Gatepass</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in gatepass_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.gatepass_desc" id="gatepass" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.gatepass_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.gatepass_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" name="close-tab" style="width: 100px;" @click="closeTab(10)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" name="save_status" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div name="edit-tab" class="d-flex">
                                <div class="position-relative">
                                    <div name="circle" class="circle mt-4"></div>
                                    <div :class="{'line': hasDeliveryData
                                                        || (logistic_data.delivery_desc && logistic_data.delivery_date && logistic_data.delivery_time),
                                            'end-line': !logistic_data.delivery_desc || !logistic_data.delivery_date || !logistic_data.delivery_time}">
                                    </div>
                                </div>
                                <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': delivery_errors.length}">
                                    <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                        <div class="row w-100 gap-2 gap-md-0">
                                            <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">For Delivery</h2>
                                            <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.delivery_desc }}</span>
                                            <span class="text-muted col-md-3 col-lg-3 col-xl-3">
                                                {{ DateFormat(logistic_data.delivery_date) }}
                                                <span v-show="logistic_data.delivery_date && logistic_data.delivery_time">|</span>
                                                {{ TimeFormat(logistic_data.delivery_time) }}
                                            </span>
                                            <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"
                                                :disabled="!logistic_data.gatepass_desc || !logistic_data.gatepass_date || !logistic_data.gatepass_time" @click="openTab(11)">
                                                <img src="#" name="edit-button-icon" alt="edit" width="25"
                                                    :class="{'opacity-50':!logistic_data.gatepass_desc || !logistic_data.gatepass_date || !logistic_data.gatepass_time}">
                                            </button>
                                        </div>
                                    </div>
                                    <div name="tab-form">
                                        <span name="close" @dblclick="close_onDoubleClick(11)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                        <div class="card py-4 px-2 px-md-5">
                                            <h2 class="fw-bold" style="font-size:larger;">For Delivery</h2>
                                            <div class="text-danger align-middle">
                                                <ul>
                                                    <li v-for="error in delivery_errors" :key="error">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                <textarea v-model="logistic_data.delivery_desc" id="delivery" placeholder="Description" class="description border rounded py-2 px-2 w-100"></textarea>
                                                <div class="w-100 d-flex flex-column gap-3">
                                                    <Datepicker v-model="logistic_data.delivery_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                    <Datepicker v-model="logistic_data.delivery_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card py-3 px-4">
                                            <div class="ms-auto">
                                                <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" style="width: 100px;" @click="closeTab(11)">Close</button>
                                                <button type="submit" class="bg-theme text-white py-2 rounded border-0" style="width: 100px;" :disabled="isLoading">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div name="edit-tab" v-show="hasDeliveryData || (logistic_data.delivery_desc && logistic_data.delivery_date && logistic_data.delivery_time)">
                                <div class="d-flex">
                                    <div class="position-relative">
                                        <div name="circle" class="circle mt-4"></div>
                                        <div class="end-line"></div>
                                    </div>
                                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100" :class="{'border-danger': container_return_errors.length}">
                                        <div name="info" class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                                            <div class="row w-100 gap-2 gap-md-0">
                                                <h2 class="fw-bold fs-large col-md-3 col-lg-3 col-xl-4">Empty Container Return</h2>
                                                <span class="text-muted col-md-4 col-lg-5 col-xl-4">{{ logistic_data.container_return_desc == 1 ? 'Empty Container has been returned' : 'Empty Container is still not returned' }}</span>
                                                <div class="d-flex flex-column text-muted col-md-3 col-lg-3 col-xl-3">
                                                    <span>
                                                        {{ DateFormat(logistic_data.container_return_date) }}
                                                        <span v-show="logistic_data.container_return_date && logistic_data.container_return_time">|</span>
                                                        {{ TimeFormat(logistic_data.container_return_time) }}
                                                    </span>
                                                    <span class="text-center"
                                                        :class="{'text-danger': lapsed}"
                                                        style="font-size: large;">{{logistic_data.container_return_readableTime}}</span>
                                                </div>
                                                <button name="edit-button" type="button" class="border-0 bg-transparent col-md-2 col-lg-1 col-xl-1"><img src="#" name="edit-button-icon" @click="openTab(12)" alt="edit" width="25"></button>
                                            </div>
                                        </div>
                                        <div name="tab-form">
                                            <span name="close" @dblclick="close_onDoubleClick(12)" class="position-absolute w-100" style="z-index: 1; height: 20px;"></span>
                                            <div class="card py-4 px-2 px-md-5">
                                                <h2 class="fw-bold" style="font-size:larger;">Empty Container Return</h2>
                                                <div class="text-danger align-middle">
                                                    <ul>
                                                        <li v-for="error in container_return_errors" :key="error">
                                                            {{ error }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="d-flex flex-column flex-md-row h-100 gap-5">
                                                    <span>Does the Empty Container Returned?</span>
                                                    <div class="d-flex mx-2">
                                                        <label for="1">Yes</label>
                                                        <input v-model="logistic_data.container_return_desc" type="radio" value="1" id="1">
                                                    </div>
                                                    <div class="d-flex mx-2">
                                                        <label for="0">No</label>
                                                        <input v-model="logistic_data.container_return_desc" type="radio" value="0" id="0">
                                                    </div>
                                                    <div class="w-100 d-flex flex-column gap-3">
                                                        <Datepicker v-model="logistic_data.container_return_date" class="datepicker" format="MMM. dd, yyyy eee" showNowButton autoApply placeholder="Select Date" :enableTimePicker="false" />
                                                        <Datepicker v-model="logistic_data.container_return_time" class="timepicker" format="hh:mm a" timePicker placeholder="Select Time" :is24="false" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card py-3 px-4">
                                                <div class="ms-auto">
                                                    <button type="button" class="bg-dark text-white py-2 rounded border-0 mx-2" style="width: 100px;" @click="closeTab(12)">Close</button>
                                                    <button type="submit" class="bg-theme text-white py-2 rounded border-0" style="width: 100px;" :disabled="isLoading">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div ref="error" class="d-none d-flex flex-column align-items-center text-center mt-5 mx-auto">
                <img src="../../../assets/imgs/icons/truck.png" alt="truck" class="w-50 opacity-75" style="object-fit: contain;">
                <h2 class="w-75 text-muted mt-4">This shipment has not yet started</h2>
            </div>

        </div>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
const editIcon = require('../../../assets/imgs/icons/edit.png')
import { format } from 'date-fns/fp'
import Datepicker from 'vue3-date-time-picker';
import PreloaderVue from '../../../components/Preloader.vue';
import 'vue3-date-time-picker/dist/main.css'
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('logistic')

export default {
  name: 'UpdateStatus',
  components: { Datepicker, PreloaderVue },
   setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const DateFormat = (date) => {
            if(!date) return ''
            return format('MMM. dd, yyyy eee', new Date(date))
        }

        const TimeFormat = (time) => {
            if(!time) return ''

            var hours = ''
            var mins = ''
            var normal_time = ''

            hours = time.hours;
            mins = time.minutes;
            normal_time = new Date('', '', '', hours, mins)
            
            return format('hh:mm a', normal_time);
        }

        return { DateFormat, TimeFormat }
    },
  data() {
      return{
        edit_tabArray: document.getElementsByName("edit-tab"),
        edit_buttonArray: document.getElementsByName("edit-button"),
        close_tabArray: document.getElementsByName("close-tab"),
        next_buttonArray: document.getElementsByName("next-button"),
        edit_buttonIconArray: document.getElementsByName("edit-button-icon"),
        close_buttonArray: document.getElementsByName("close"),
        circleArray: document.getElementsByName("circle"),
        infoArray: document.getElementsByName("info"),
        formArray: document.getElementsByName("tab-form"),
        date_inputArray: document.getElementsByClassName("datepicker"),
        time_inputArray: document.getElementsByClassName("timepicker"),
        description_textArray: document.getElementsByClassName("description"),
        hasError: false,
        isUpdating: false,
        next_to_open: -1,
        timepicker: false,
        logistic_data: {},
        srn_to_search: '',
        method: '',
        srn_label: 'Create SRN',
        srn_button: 'Save',
        hasDeliveryData: false,
        lapsed: false,
        message: ''
    }
  },
  beforeMount() {
    if(this.$store.getters['get_perm']('storeLogistic')
        || (this.$store.getters['get_perm']('updateLogistic')
            && this.$store.getters['get_perm']('showLogistic'))) return
    else this.$router.push('/dashboard')
  },
  async mounted() {
    for(var i = 0; i < this.edit_tabArray.length; i++) {
        this.edit_buttonIconArray[i].src = editIcon;
        this.circleArray[i].classList.add('bg-muted');
        this.formArray[i].classList.add('d-none');
        

        if(!(this.description_textArray[i] === undefined)) this.description_textArray[i].required = false;
        this.date_inputArray[i].required = false;
        this.time_inputArray[i].required = false;
    }

    if(this.$store.getters['get_perm']('showLogistic')) {
        if(this.$route.query.srn) {
            await this.getLogistic(this.$route.query.srn)
            return
        }

        if(!this.$store.getters['get_perm']('storeLogistic')
            && this.$store.getters['get_perm']('updateLogistic')) {
            this.method = 'patch'

            this.isUpdating = true

            this.srn_label = 'Update SRN';
            this.srn_button = 'Update';
        }
        else {
            Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data)
            this.next_to_open = this.logistic_data.last_edited + 1
            this.initialize_dom()
        }
    }
  },
  methods: {
    initialize_dom: function() {
        if(this.next_to_open >= 0) {
            if(!this.hasError) { //If hasError is true
                this.timepicker = true
                //close all tabs
                for(var j = 0; j < this.edit_tabArray.length; j++) {
                    this.circleArray[j].classList.remove('bg-theme');
                    this.infoArray[j].classList.remove('d-none'); //visible
                    this.formArray[j].classList.add('d-none'); //invisible
                    this.circleArray[j].classList.add('bg-muted');
                }

                this.$refs.error.classList.add("d-none");
                this.$refs.form.classList.remove("d-none");
                
                if(this.next_to_open > 0) {
                    this.edit_tabArray[this.next_to_open].scrollIntoView({
                        block: "center",
                    });
                }

                for(var d = 0; d <= this.next_to_open; d++) {
                    this.circleArray[d].classList.remove('bg-muted');
                    this.circleArray[d].classList.add('bg-theme');
                }
                
                this.formArray[this.next_to_open].classList.remove('d-none'); //visible
                this.infoArray[this.next_to_open].classList.add('d-none'); //invisible
                if(!(this.description_textArray[this.next_to_open] === undefined)) this.description_textArray[this.next_to_open].required = true;
                this.date_inputArray[this.next_to_open].required = true;
                this.time_inputArray[this.next_to_open].required = true;

                return
            }
        }
            
        this.$refs.error.classList.remove("d-none");
        this.$refs.form.classList.add("d-none");
    },
    close_onDoubleClick: function(close_button_no) {
        this.formArray[close_button_no].classList.add('d-none'); //visible
        this.infoArray[close_button_no].classList.remove('d-none'); //invisible
        this.circleArray[close_button_no].classList.add('bg-muted');
        this.circleArray[close_button_no].classList.remove('bg-theme');

        if(!(this.description_textArray[close_button_no] === undefined)) this.description_textArray[close_button_no].required = false;
        this.date_inputArray[close_button_no].required = false;
        this.time_inputArray[close_button_no].required = false;
    },
    closeTab(tab_no) {
        if(tab_no > this.next_to_open) {
            this.circleArray[tab_no].classList.add('bg-muted');
            this.circleArray[tab_no].classList.remove('bg-theme');
        }
        this.formArray[tab_no].classList.add('d-none');
        this.infoArray[tab_no].classList.remove('d-none');

        if(!(this.description_textArray[tab_no] === undefined)) this.description_textArray[tab_no].required = false;
        this.date_inputArray[tab_no].required = false;
        this.time_inputArray[tab_no].required = false;
    },
    openTab(tab_no) {
        this.formArray[tab_no].classList.remove('d-none');
        this.infoArray[tab_no].classList.add('d-none');
        this.circleArray[tab_no].classList.remove('bg-muted');
        this.circleArray[tab_no].classList.add('bg-theme');

        if(!(this.description_textArray[tab_no] === undefined)) this.description_textArray[tab_no].required = true;
        this.date_inputArray[tab_no].required = true;
        this.time_inputArray[tab_no].required = true;
    },
    regexgrouping(obj) {
        const desc = '(.*?)(?<=_)desc'
        const date = '(.*?)(?<=_)date'
        const time = '(.*?)(?<=_)time'

        var descs = []
        var dates = []
        var times = []

        var desc_re = new RegExp(desc), desc_key;
        for (desc_key in obj) {
            if(desc_re.test(desc_key) && obj[desc_key]) {
                descs[desc_key] = obj[desc_key];
            }
        }

        var date_re = new RegExp(date), date_key;
        for (date_key in obj) {
            if(date_re.test(date_key) && obj[date_key]) {
                dates[date_key] = this.DateFormat(obj[date_key]);
            }  
        }

        var time_re = new RegExp(time), time_key;
        for (time_key in obj) {
            if(time_re.test(time_key) && obj[time_key]) {
                times[time_key] = this.TimeFormat(obj[time_key]);
            }
        }
            
        return { ...descs, ...dates, ...times }
    },
    async createLogistic() {
        var data = this.regexgrouping(this.logistic_data)

        var last_edited = -1;
        if(Object.getOwnPropertyDescriptor(data, 'doc_desc')) last_edited = 0
        if(Object.getOwnPropertyDescriptor(data, 'etd_desc')) last_edited = 1
        if(Object.getOwnPropertyDescriptor(data, 'eta_desc')) last_edited = 2
        if(Object.getOwnPropertyDescriptor(data, 'etb_desc')) last_edited = 3
        if(Object.getOwnPropertyDescriptor(data, 'endorsement_desc')) last_edited = 4
        if(Object.getOwnPropertyDescriptor(data, 'customs_desc')) last_edited = 5
        if(Object.getOwnPropertyDescriptor(data, 'assessment_desc')) last_edited = 6
        if(Object.getOwnPropertyDescriptor(data, 'paidDuties_desc')) last_edited = 7
        if(Object.getOwnPropertyDescriptor(data, 'arrastre_desc')) last_edited = 8
        if(Object.getOwnPropertyDescriptor(data, 'tabs_desc')) last_edited = 9
        if(Object.getOwnPropertyDescriptor(data, 'gatepass_desc')) last_edited = 10
        if(Object.getOwnPropertyDescriptor(data, 'delivery_desc')) last_edited = 11

        Object.assign(data, {
            srn: this.logistic_data.srn,
            items: this.logistic_data.items,
            last_edited: last_edited,
            remind_at: this.logistic_data.remind_at
        })
        
        if(this.method === 'patch') {
            Object.assign(data, { id: this.logistic_data.id, _method: 'PATCH' })
            await this.$store.dispatch('logistic/updateLogistic', data).then(() => {
                this.message = this.$store.state.logistic.message
            })
        }
        else {
            await this.$store.dispatch('logistic/createLogistic', data).then(() => {
                this.message = this.$store.state.logistic.message
            })
            const obj = this.$store.state.logistic.errors.errors
            for (var key in obj) {
                for(var i = 0; i < obj[key].length; i++) {
                    if(obj[key][i]) {
                        return
                    }
                }
            }
            Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data)
        }

        if(this.message.includes('ucces')) {
            // Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data)
            if(this.method === 'patch') {
                this.infoArray[last_edited].classList.remove('d-none'); //visible
                this.formArray[last_edited].classList.add('d-none'); //invisible

                this.infoArray[last_edited + 1].classList.add('d-none'); //visible
                this.formArray[last_edited + 1].classList.remove('d-none'); //invisible
            }
            this.$refs.logistic_main.scrollIntoView()
        }
    },
    async getLogistic(srn) {
        if(this.$store.state.logistic.single_logistic_data.srn == srn) {
            this.method = 'patch'

            this.isUpdating = true
            this.$refs.error.classList.remove("d-none");
            this.$refs.form.classList.add("d-none");

            this.srn_label = 'Update SRN';
            this.srn_button = 'Update';
            Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data)
            this.logistic_data.last_edited >= 11
                ? this.next_to_open = this.logistic_data.last_edited
                : this.next_to_open = this.logistic_data.last_edited + 1
            this.initialize_dom()
        }
        else {
            await this.$store.dispatch('logistic/searchBySrn', { search: srn }).then(() => {
                this.message = this.$store.state.logistic.message
                if(this.message.includes('Not Found')) {
                    this.hasError = true
                    return this.initialize_dom()
                }
                this.method = 'patch'

                this.hasError = false
                this.isUpdating = true
                this.$refs.error.classList.remove("d-none");
                this.$refs.form.classList.add("d-none");

                this.srn_label = 'Update SRN';
                this.srn_button = 'Update';
                
                Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data)
                this.logistic_data.last_edited >= 11
                    ? this.next_to_open = this.logistic_data.last_edited
                    : this.next_to_open = this.logistic_data.last_edited + 1
                    
                this.initialize_dom()
            });
        }
        
        if(this.logistic_data.delivery_desc !== null) {
            
            if(this.logistic_data.container_return_readableTime) {
                this.lapsed = this.logistic_data.container_return_readableTime.includes('ago') ? true : false
            }
            this.hasDeliveryData = true
        }
    },
    async goBack() {
        if(this.hasError) {
            this.hasError = false
            this.$store.commit('logistic/RESET_MESSAGE')
            this.next_to_open = 0
            this.initialize_dom()
        }
        if(this.isUpdating) {
            this.isUpdating = false
            this.$store.commit('logistic/RESET_SINGLE_LOGISTIC')
            this.$store.commit('logistic/reset_errors')
            Promise.resolve(Object.assign(this.logistic_data, this.$store.state.logistic.single_logistic_data))
            delete this.logistic_data.id
            Promise.resolve(this.next_to_open = this.logistic_data.last_edited + 1)
            this.method = ''
            this.hasDeliveryData = false
            this.lapsed = false;
            
            this.srn_label = 'Create SRN';
            this.srn_button = 'Save';
            this.initialize_dom()
            for(var i = 0; i < this.description_textArray.length; i++) {
                if(!(this.description_textArray[i] === undefined)) this.description_textArray[i].required = false;
            }
       
            this.$refs.logistic_main.scrollIntoView()
        }
    },
    async test() {
        await this.$store.dispatch('logistic/poll_reminder');
    }
  },
    computed: {
        doc_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var doc_error_list = []
                const doc = 'doc(.*?)(?<=_)'
                var doc_re = new RegExp(doc), doc_key;
                
                for (doc_key in obj) {
                    if(doc_re.test(doc_key) && doc_key){
                        for(var i = 0; i < obj[doc_key].length; i++) {
                            doc_error_list.push(obj[doc_key][i]);
                        }
                    }
                }

                return doc_error_list
            }
        },
        etd_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var etd_error_list = []
                const etd = 'etd(.*?)(?<=_)'
                var etd_re = new RegExp(etd), etd_key;
                
                for (etd_key in obj) {
                    if(etd_re.test(etd_key) && etd_key){
                        for(var i = 0; i < obj[etd_key].length; i++) {
                            etd_error_list.push(obj[etd_key][i]);
                        }
                    }
                }

                return etd_error_list
            }
        },
        eta_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var eta_error_list = []
                const eta = 'eta(.*?)(?<=_)'
                var eta_re = new RegExp(eta), eta_key;
                
                for (eta_key in obj) {
                    if(eta_re.test(eta_key) && eta_key){
                        for(var i = 0; i < obj[eta_key].length; i++) {
                            eta_error_list.push(obj[eta_key][i]);
                        }
                    }
                }

                return eta_error_list
            }
        },
        etb_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var etb_error_list = []
                const etb = 'etb(.*?)(?<=_)'
                var etb_re = new RegExp(etb), etb_key;
                
                for (etb_key in obj) {
                    if(etb_re.test(etb_key) && etb_key){
                        for(var i = 0; i < obj[etb_key].length; i++) {
                            etb_error_list.push(obj[etb_key][i]);
                        }
                    }
                }

                return etb_error_list
            }
        },
        customs_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var customs_error_list = []
                const customs = 'customs(.*?)(?<=_)'
                var customs_re = new RegExp(customs), customs_key;
                
                for (customs_key in obj) {
                    if(customs_re.test(customs_key) && customs_key){
                        for(var i = 0; i < obj[customs_key].length; i++) {
                            customs_error_list.push(obj[customs_key][i]);
                        }
                    }
                }

                return customs_error_list
            }
        },
        endorsement_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var endorsement_error_list = []
                const endorsement = 'endorsement(.*?)(?<=_)'
                var endorsement_re = new RegExp(endorsement), endorsement_key;
                
                for (endorsement_key in obj) {
                    if(endorsement_re.test(endorsement_key) && endorsement_key){
                        for(var i = 0; i < obj[endorsement_key].length; i++) {
                            endorsement_error_list.push(obj[endorsement_key][i]);
                        }
                    }
                }

                return endorsement_error_list
            }
        },
        assessment_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var assessment_error_list = []
                const assessment = 'assessment(.*?)(?<=_)'
                var assessment_re = new RegExp(assessment), assessment_key;
                
                for (assessment_key in obj) {
                    if(assessment_re.test(assessment_key) && assessment_key){
                        for(var i = 0; i < obj[assessment_key].length; i++) {
                            assessment_error_list.push(obj[assessment_key][i]);
                        }
                    }
                }

                return assessment_error_list
            }
        },
        paidDuties_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var paidDuties_error_list = []
                const paidDuties = 'paidDuties(.*?)(?<=_)'
                var paidDuties_re = new RegExp(paidDuties), paidDuties_key;
                
                for (paidDuties_key in obj) {
                    if(paidDuties_re.test(paidDuties_key) && paidDuties_key){
                        for(var i = 0; i < obj[paidDuties_key].length; i++) {
                            paidDuties_error_list.push(obj[paidDuties_key][i]);
                        }
                    }
                }

                return paidDuties_error_list
            }
        },
        arrastre_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var arrastre_error_list = []
                const arrastre = 'arrastre(.*?)(?<=_)'
                var arrastre_re = new RegExp(arrastre), arrastre_key;
                
                for (arrastre_key in obj) {
                    if(arrastre_re.test(arrastre_key) && arrastre_key){
                        for(var i = 0; i < obj[arrastre_key].length; i++) {
                            arrastre_error_list.push(obj[arrastre_key][i]);
                        }
                    }
                }

                return arrastre_error_list
            }
        },
        tabs_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var tabs_error_list = []
                const tabs = 'tabs(.*?)(?<=_)'
                var tabs_re = new RegExp(tabs), tabs_key;
                
                for (tabs_key in obj) {
                    if(tabs_re.test(tabs_key) && tabs_key){
                        for(var i = 0; i < obj[tabs_key].length; i++) {
                            tabs_error_list.push(obj[tabs_key][i]);
                        }
                    }
                }

                return tabs_error_list
            }
        },
        gatepass_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var gatepass_error_list = []
                const gatepass = 'gatepass(.*?)(?<=_)'
                var gatepass_re = new RegExp(gatepass), gatepass_key;
                
                for (gatepass_key in obj) {
                    if(gatepass_re.test(gatepass_key) && gatepass_key){
                        for(var i = 0; i < obj[gatepass_key].length; i++) {
                            gatepass_error_list.push(obj[gatepass_key][i]);
                        }
                    }
                }

                return gatepass_error_list
            }
        },
        delivery_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var delivery_error_list = []
                const delivery = 'delivery(.*?)(?<=_)'
                var delivery_re = new RegExp(delivery), delivery_key;
                
                for (delivery_key in obj) {
                    if(delivery_re.test(delivery_key) && delivery_key){
                        for(var i = 0; i < obj[delivery_key].length; i++) {
                            delivery_error_list.push(obj[delivery_key][i]);
                        }
                    }
                }

                return delivery_error_list
            }
        },
        container_return_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var container_return_error_list = []
                const container_return = 'container_return(.*?)(?<=_)'
                var container_return_re = new RegExp(container_return), container_return_key;
                
                for (container_return_key in obj) {
                    if(container_return_re.test(container_return_key) && container_return_key){
                        for(var i = 0; i < obj[container_return_key].length; i++) {
                            container_return_error_list.push(obj[container_return_key][i]);
                        }
                    }
                }

                return container_return_error_list
            }
        },
        other_errors: {
            get() {
                var obj = this.$store.state.logistic.errors.errors
                var other_error_list = []
                if(Object.getOwnPropertyDescriptor(obj, 'srn') && obj.srn){
                    for(var key in obj.srn) {
                        other_error_list.push(obj.srn[key]);
                    }
                }
                
                if(Object.getOwnPropertyDescriptor(obj, 'items') && obj.items){
                    for(var items_key in obj.items) {
                        other_error_list.push(obj.items[items_key]);
                    }
                }

                if(Object.getOwnPropertyDescriptor(obj, 'last_edited') && obj.last_edited){
                    for(var last_edited_key in obj.last_edited) {
                        other_error_list.push(obj.last_edited[last_edited_key]);
                    }
                }

                return other_error_list
            }
        },
        isLoading: {
            get() {
                return this.$store.state.logistic.isLoading
            }
        },
    }
}
</script>